body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 25px 0;
}

section {
  padding: 25px;
  border-bottom: 1px solid #e1e1e1;
}

ul {
  list-style-type: none;
  margin: 0;
}

button {
  margin: 0 5px;
}

input { 
  padding: 5px;
}

.response {
  margin: 0 auto;
  width: 400px;
  padding: 10px;
  border: 1px solid #e1e1e1;
  word-wrap: break-word;
}

.response:empty {
  display: none;
}

table {
  width: 100%;
  padding: 25px;
  text-align: left;
}

thead {
  font-weight: bold;
}

td {
  border-bottom: 1px solid #e1e1e1;
}
